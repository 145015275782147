import React from "react";
import { useAppSelector } from "../hooks/storeHooks";
import Menu from "./Menu";

const Layout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const loggedIn = useAppSelector((s) => !!s.authReducer.token);
  return (
    <div className="h-screen">
      <div className="h-full bg-pineWhite" style={{ marginTop: loggedIn ? "58px" : "0" }}>
        {loggedIn && <Menu />}
        {children}</div>
    </div >
  );
};

export default Layout;
