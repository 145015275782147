import {
    Dialog,
    DialogContent,
} from "@mui/material";
interface Props {
    open: boolean;
    onClose: (event?: {}, reason?: "backdropClick" | "escapeKeyDown") => void;
    name: string;
}

const ShowNameModal: React.FC<Props> = ({
    open,
    onClose,
    name,
}) => {
    return (
        <Dialog
            open={open}
            onClose={onClose}
            onTransitionEnd={() => !open}
        >
            <DialogContent>
                <div className="flex flex-col gap-2 mt-5">
                    <h1 className="text-center text-2xl">{name}</h1>
                </div>
            </DialogContent>
        </Dialog>
    )
}

export default ShowNameModal