import { Card, Button, Alert } from "@mui/material";
import {
    DataGrid,
    GridColDef,
    esES,
    GridToolbarContainer,
    GridToolbarFilterButton,
} from "@mui/x-data-grid";
import { useAppSelector } from "../../../hooks/storeHooks";
import { fetchInvoices, generateInvoicesXls, getInvoicesFromSII, getInvoicesSummary } from "../../../services/InvoiceService";
import { fetchSales, getSalesFromSII, getSalesSummary, generateSalesXls } from "../../../services/SalesService";
import { useState, useEffect } from "react";
import PaymentStatusModal from "../Purchases/PaymentStatusModal";
import SalesStatusModal from "../Sales/SalesStatusModal";
import { set } from "date-fns";
import ShowNameModal from "./ShowNameModal";

interface Props {
    month: string;
    year: string;
    type: "purchase" | "sale";
}
function CustomToolbar() {
    return (
        <GridToolbarContainer>
            <GridToolbarFilterButton />
        </GridToolbarContainer>
    );
}

const purchaseColumns: GridColDef[] = [
    { field: "dteType", headerName: "Tipo DTE", width: 100 },
    { field: "purchaseType", headerName: "Tipo Compra", width: 120 },
    { field: "providerRut", headerName: "RUT Proveedor", width: 120 },
    { field: "providerName", headerName: "Nombre Proveedor", width: 200 },
    { field: "invoiceNumber", headerName: "Número Documento", width: 100 },
    { field: "date", headerName: "Fecha", width: 100 },
    { field: "receptionDate", headerName: "Fecha Recepción", width: 100 },
    { field: "acuseDate", headerName: "Fecha Acuse", width: 100 },
    { field: "exentAmount", headerName: "Monto Exento", width: 100 },
    { field: "netAmount", headerName: "Monto Neto", width: 100 },
    { field: "recuperableIVA", headerName: "IVA Recuperable", width: 100 },
    { field: "total", headerName: "Total", width: 100 },
    { field: "paymentStatus", headerName: "Estado Pago", width: 100 },
    {
        field: "paymentDate", headerName: "Fecha Pago", width: 100, valueGetter(params) {

            if (!params.row.paymentDate) return "";
            const paymentDate = new Date(params.row.paymentDate);
            return paymentDate.toLocaleDateString("es-ES");
        },
    },
    { field: "costCenter", headerName: "Centro de Costo", width: 100 },
    { field: "paymentMethod", headerName: "Metodo De Pago", width: 100 },
    { field: "referenceFolio", headerName: "Folio Referencia", width: 100 },
    { field: "nonRecuperableIVA", headerName: "IVA No Recuperable", width: 100 },

];

const saleColumns: GridColDef[] = [
    { field: "dteType", headerName: "Tipo DTE", width: 100 },
    { field: "salesType", headerName: "Tipo Venta", width: 120 },
    { field: "customerRut", headerName: "RUT Cliente", width: 120 },
    { field: "customerName", headerName: "Nombre Cliente", width: 200 },
    { field: "invoiceNumber", headerName: "Número Documento", width: 100 },
    { field: "date", headerName: "Fecha", width: 100 },
    { field: "receptionDate", headerName: "Fecha Recepción", width: 100 },
    { field: "acuseDate", headerName: "Fecha Acuse", width: 100 },
    { field: "rejectionDate", headerName: "Fecha Rechazo", width: 100 },
    { field: "exentAmount", headerName: "Monto Exento", width: 100 },
    { field: "netAmount", headerName: "Monto Neto", width: 100 },
    { field: "IVA", headerName: "IVA", width: 100 },
    { field: "total", headerName: "Total", width: 100 },
    { field: "paymentStatus", headerName: "Estado Pago", width: 100 },
    {
        field: "paymentDate", headerName: "Fecha Pago", width: 100, valueGetter(params) {
            if (!params.row.paymentDate) return "";
            const paymentDate = new Date(params.row.paymentDate);
            return paymentDate.toLocaleDateString("es-ES");
        },
    },
    { field: "costCenter", headerName: "Centro de Costo", width: 100 },
    { field: "observations", headerName: "Observaciones", width: 100 },
    {
        field: "daysUntilPayment", headerName: "Días Hasta Pago", width: 100, valueGetter(params) {
            var dateParts = params.row.date.split("/");
            var date = new Date(dateParts[2], dateParts[1] - 1, dateParts[0]);
            let dateToCompare = new Date(Date.now());
            if (params.row.paymentDate) dateToCompare = new Date(params.row.paymentDate);
            const diffTime = Math.abs(dateToCompare.getTime() - date.getTime());
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
            return diffDays;
        },
    },
    { field: "referenceFolio", headerName: "Folio Referencia", width: 100 },

]

const MonthlyView: React.FC<Props> = ({ month, year, type }) => {
    const { invoices, isLoading: isLoadingInvoice } = useAppSelector((state) => state.invoiceReducer);
    const { salesInvoices, isLoading: isLoadingSale } = useAppSelector((state) => state.salesInvoiceReducer);
    const [sincronizing, setSincronizing] = useState(false);
    const [sincronized, setSincronized] = useState(false);
    const [error, setError] = useState(false);
    const [openPurchaseModal, setOpenPurchaseModal] = useState(false);
    const [openSaleModal, setOpenSaleModal] = useState(false);
    const [selectedInvoice, setSelectedInvoice] = useState<Invoice | null>(null);
    const [selectedSaleInvoice, setSelectedSaleInvoice] = useState<SalesInvoice | null>(null);
    const [filtered, setFiltered] = useState(false);
    const [summary, setSummary] = useState<{
        exentTotal: number,
        netTotal: number,
        ivaTotal: number,
        total: number
    }>({
        exentTotal: 0,
        netTotal: 0,
        ivaTotal: 0,
        total: 0
    });
    const [pendingAmount, setPendingAmount] = useState(0);
    const [openNameModal, setOpenNameModal] = useState(false);
    const [name, setName] = useState("");

    const handlePurchaseSinchronize = () => {
        setSincronizing(true);
        getInvoicesFromSII(month, year)
            .then(async (res) => {
                if (res) {
                    setSincronized(true);
                    await fetchInvoices(month, year);
                    getInvoicesSummary(month, year).then((res) => {
                        setSummary(res);
                    })
                }
            })
            .catch((err) => {
                console.log(err);
                setError(true);
            })
            .finally(() => {
                setSincronizing(false);
            })
    }
    const handleSalesSinchronize = () => {
        setSincronizing(true);
        getSalesFromSII(month, year)
            .then(async (res) => {
                if (res) {
                    setSincronized(true);
                    await fetchSales(month, year);
                    getSalesSummary(month, year).then((res) => {
                        setSummary(res);
                    })
                }
            })
            .catch((err) => {
                console.log(err);
                setError(true);
            })
            .finally(() => {
                setSincronizing(false);

            })
    }

    const handleDoubleClick = (id: string) => {
        if (type === "purchase") {
            setOpenPurchaseModal(true);
            const invoice = invoices.find((invoice) => invoice._id === id);
            if (invoice) {
                setSelectedInvoice(invoice);
            }
        }
        else {
            setOpenSaleModal(true);
            const invoice = salesInvoices.find((invoice) => invoice._id === id);
            if (invoice) {
                setSelectedSaleInvoice(invoice);
            }
        }
    }

    useEffect(() => {
        if (type === "purchase") {
            fetchInvoices(month, year);
            getInvoicesSummary(month, year).then((res) => {
                setSummary(res);
            })
        }
        else {
            fetchSales(month, year);
            getSalesSummary(month, year).then((res) => {
                setSummary(res);
            })
        }
        setFiltered(false);
    }, [month, year, type]);

    useEffect(() => {
        if (type === "purchase") {
            const pending = invoices.filter((invoice) => invoice.paymentStatus === "Pendiente");
            let pendingAmount = 0;
            pending.forEach((invoice) => {
                pendingAmount += invoice.total;
            })
            setPendingAmount(pendingAmount);
        }
        else {
            const pending = salesInvoices.filter((invoice) => invoice.paymentStatus === "Pendiente");
            let pendingAmount = 0;
            pending.forEach((invoice) => {
                pendingAmount += invoice.total;
            })
            setPendingAmount(pendingAmount);
        }
    }, [invoices, salesInvoices, type])

    return (
        <div className="mt-5 h-full overflow-auto">
            {sincronized && (<Alert
                severity="success"
                onClose={() => {
                    setSincronized(false);
                }}>
                Sincronizado correctamente
            </Alert>)}
            {error && (<Alert
                severity="error"
                onClose={() => {
                    setError(false);
                }}>
                Error al sincronizar
            </Alert>)}
            <div className="m-2 flex gap-2">
                {sincronizing ? <div>Sincronizando...</div> : (<Button
                    variant="contained"
                    color="primary"
                    onClick={type === "purchase" ? handlePurchaseSinchronize : handleSalesSinchronize}> Actualizar con el SII</Button>)}
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                        setFiltered(!filtered);
                    }}
                > Ver {filtered ? "Todo" : "Pendientes"}</Button>
                <Button
                    variant="contained"
                    color="success"
                    onClick={async () => {
                        if (type === "purchase") {
                            await generateInvoicesXls(month, year);
                        }
                        else {
                            await generateSalesXls(month, year);
                        }
                    }}
                > Generar XLS</Button>
            </div>
            {isLoadingInvoice || isLoadingSale ? <div>Cargando...</div> : (<Card>
                <div className="m-2 flex gap-2">
                    <div>Resumen:</div>
                    <div>Monto Exento: {summary.exentTotal.toLocaleString("es-ES")}</div>
                    <div>Monto Neto: {summary.netTotal.toLocaleString("es-ES")}</div>
                    <div>IVA: {summary.ivaTotal.toLocaleString("es-ES")}</div>
                    <div>Monto Total: {summary.total.toLocaleString("es-ES")}</div>
                    <div>Monto Pendiente: {pendingAmount.toLocaleString("es-ES")}</div>
                </div>
                <DataGrid
                    sx={{ padding: 2 }}
                    autoHeight={true}
                    rows={type === "purchase" ? invoices.filter((invoice) => !filtered || invoice.paymentStatus === "Pendiente") : salesInvoices.filter((invoice) => !filtered || invoice.paymentStatus === "Pendiente")}
                    columns={type === "purchase" ? purchaseColumns : saleColumns}
                    pageSize={10}
                    getRowId={(row) => row._id}
                    localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                    components={{ Toolbar: CustomToolbar }}
                    onRowDoubleClick={(row) => {
                        handleDoubleClick(row.id as string);
                    }}
                    onCellClick={(params) => {
                        if (params.field === "customerName") {
                            setName(params.row.customerName as string);
                            setOpenNameModal(true);
                        }
                        if (params.field === "providerName") {
                            setName(params.row.providerName as string);
                            setOpenNameModal(true);

                        }
                    }}
                ></DataGrid>
                <ShowNameModal
                    open={openNameModal}
                    onClose={() => {
                        setOpenNameModal(false);
                    }}
                    name={name}
                />
                <PaymentStatusModal
                    open={openPurchaseModal}
                    onClose={() => {
                        setOpenPurchaseModal(false);
                    }}
                    purchase={selectedInvoice}
                />
                <SalesStatusModal
                    open={openSaleModal}
                    onClose={() => {
                        setOpenSaleModal(false);
                    }}
                    sale={selectedSaleInvoice}
                />
            </Card>)}
        </div>
    )
}

export default MonthlyView


