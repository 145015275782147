import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from "@mui/material";
import { useEffect } from "react";
import { useForm } from "../../../hooks/useForm";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { updateInvoice } from "../../../services/InvoiceService";

interface Props {
    open: boolean;
    onClose: (event?: {}, reason?: "backdropClick" | "escapeKeyDown") => void;
    purchase: Invoice | null;
}

const PaymentStatusModal: React.FC<Props> = ({
    open,
    onClose,
    purchase,
}) => {
    const initialValues = {
        referenceFolio: "",
        paymentMethod: "",
        paymentStatus: "",
        paymentDate: new Date(),
        costCenter: "",
    };
    const [values, setValues, handleChange] = useForm(initialValues);
    useEffect(() => {
        if (purchase !== null) {
            setValues({
                referenceFolio: purchase.referenceFolio ? purchase.referenceFolio : "",
                paymentMethod: purchase.paymentMethod ? purchase.paymentMethod : "",
                paymentStatus: purchase.paymentStatus ? purchase.paymentStatus : "",
                paymentDate: purchase.paymentDate ? purchase.paymentDate : new Date(),
                costCenter: purchase.costCenter ? purchase.costCenter : "",
            });
        }
    }, [purchase, setValues]);

    return (
        <Dialog
            open={open}
            onClose={onClose}
            onTransitionEnd={() => !open && setValues(initialValues)}
        >
            <DialogTitle>Detalles Documento</DialogTitle>
            <DialogContent>
                <div className="flex flex-col gap-2 mt-5">
                    <TextField
                        onChange={handleChange}
                        id="costCenter"
                        label="Centro de Costo"
                        name="costCenter"
                        value={values.costCenter}
                        multiline
                        rows={4}
                    ></TextField>
                    <TextField
                        onChange={handleChange}
                        id="paymentMethod"
                        label="Metodo de pago"
                        value={values.paymentMethod}
                        multiline
                        rows={4}
                    ></TextField>
                    <FormControl>
                        <InputLabel id="paymentStatus">Estado del Pago</InputLabel>
                        <Select
                            onChange={(e) => {
                                setValues({ ...values, paymentStatus: e.target.value });
                            }}
                            id="paymentStatus"
                            label="Estado del Pago"
                            value={values.paymentStatus}
                        >
                            <MenuItem value="Pendiente">Pendiente</MenuItem>
                            <MenuItem value="Pagado">Pagado</MenuItem>
                            <MenuItem value="Nula">Nula</MenuItem>
                            <MenuItem value="N/A">N/A</MenuItem>
                        </Select>
                    </FormControl>
                    {values.paymentStatus === "Pagado" && (<LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                            label="Fecha de Pago"
                            inputFormat="dd/MM/yyyy"
                            value={values.paymentDate}
                            onChange={(newValue) => {
                                setValues({ ...values, paymentDate: newValue! });
                            }}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </LocalizationProvider>)}
                    {(purchase?.type === "61" || purchase?.type === "56") && (
                        <TextField
                            onChange={handleChange}
                            id="referenceFolio"
                            label="Folio Referencia"
                            value={values.referenceFolio}
                        ></TextField>
                    )}
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Cancelar
                </Button>
                <Button
                    onClick={() => {
                        let valuesToSend;
                        if (values.paymentStatus === "Pagado") {
                            valuesToSend = values;
                        }
                        else {
                            valuesToSend = {
                                paymentStatus: values.paymentStatus,
                                paymentMethod: values.paymentMethod,
                                costCenter: values.costCenter,
                                referenceFolio: values.referenceFolio,
                            }
                        }
                        updateInvoice(purchase!._id, valuesToSend);
                        onClose();
                    }}
                    color="primary"
                    variant="contained"
                >
                    Actualizar
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default PaymentStatusModal;