const Home: React.FC = () => {
  return (
    <div className="home">
      <img
        src={"bienvenido.png"}
        className="welcome-image mt-10"
        alt="Bienvenido"
      />
      <div className="welcome-title">¡Bienvenid@!</div>
    </div>
  );
};
export default Home;
