interface State {
  isLoading: boolean;
  salesInvoices: SalesInvoice[];
}

const initialState: State = {
  isLoading: false,
  salesInvoices: [],
};

export enum SalesActions {
  RequestSales = "REQUEST_SALES",
  ReceiveSales = "RECEIVE_SALES",
  UpdateSale = "UPDATE_SALE",
}

const reducer = (
  state: State = initialState,
  { type, payload }: { type: SalesActions; payload: any }
): State => {
  switch (type) {
    case SalesActions.RequestSales:
      return { ...state, isLoading: true };
    case SalesActions.ReceiveSales:
      return { ...state, isLoading: false, salesInvoices: payload };
    case SalesActions.UpdateSale:
      const updatedIndex = state.salesInvoices.findIndex((p) => p._id === payload._id);
      if (updatedIndex === -1) {
        // If the invoice with the specified _id is not found, return the state unchanged
        return state;
      }

      const updatedSalesInvoices = [...state.salesInvoices];
      updatedSalesInvoices[updatedIndex] = payload;

      return {
        ...state,
        salesInvoices: updatedSalesInvoices,
      };

    default:
      return state;
  }
};

export default reducer;