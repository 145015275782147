interface State {
  isLoading: boolean;
  invoices: Invoice[];
}
const initialState: State = {
  isLoading: false,
  invoices: [],
};
export enum InvoiceActions {
  RequestInvoices = "REQUEST_INVOICES",
  ReceiveInvoices = "RECEIVE_INVOICES",
  UpdateInvoice = "UPDATE_INVOICE",
};
const reducer = (
  state: State = initialState,
  { type, payload }: { type: InvoiceActions; payload: any }
): State => {
  switch (type) {
    case InvoiceActions.RequestInvoices:
      return { ...state, isLoading: true };
    case InvoiceActions.ReceiveInvoices:
      return { ...state, isLoading: false, invoices: payload };
    case InvoiceActions.UpdateInvoice:
      const updatedIndex = state.invoices.findIndex((p) => p._id === payload._id);
      if (updatedIndex === -1) {
        // If the invoice with the specified _id is not found, return the state unchanged
        return state;
      }

      const updatedInvoices = [...state.invoices];
      updatedInvoices[updatedIndex] = payload;

      return {
        ...state,
        invoices: updatedInvoices,
      };

    default:
      return state;
  }
};
export default reducer;
