import { combineReducers } from "redux";
import invoiceReducer from "./InvoiceReducer";
import authReducer from "./AuthReducer";
import salesInvoiceReducer from "./SalesInvoiceReducer";

const rootReducer = combineReducers({
  invoiceReducer,
  authReducer,
  salesInvoiceReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
